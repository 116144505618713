/* eslint-disable global-require */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, { useContext, useEffect, useState } from 'react';
import router from 'next/router';
import Link from 'next/link';
// import Head from 'next/head';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Container from '@material-ui/core/Container';
import LazyLoad from 'react-lazyload';
import { UserContext } from '../components/UserContext';
import { trackGA_PageView, trackGA_Event } from '../utilsMethods/trackGA';
import fetchSwal from '../lib/fetchSwal';
import { SignUp } from '../components/signup';
import Layout from '../components/homeLayout';
import redirectTo from '../lib/redirectTo';
import Footer from '../components/Footer';
import { generateGuid } from '../utilsMethods/getGAGuid';
import { QueryPreservingLink } from '../components/QueryPreservingLink';

declare var fbq: any;

// import award1 from '../components/landing/award1';
// import award2 from '../components/landing/award2';
// import award3 from '../components/landing/award3';
// import award4 from '../components/landing/award4';
// import WistiaEmbed from '../components/wistia-embed';
// import { Quotes } from '../components/Icons';

export const DEFAULT_BLOCK_TEXT = {
  block1: <>We&apos;re still accepting and processing PPP Loan Applications</> as JSX.Element | string,
  block2: 'Whether you’re a small business, a 501(3)(c) non-profit, an independent contractor, a minority business, a women owned business or self-employed, we welcome you to PPPays',
  block3: 'You are eligible for a PPP loan from PPPays if you are a small or medium sized business or a nonprofit with less than 500 employees',
  block4: ['<500', 'Employees', 'Most business and nonprofits with 500 or fewer employees are qualified to apply'] as [string, string, string],
  block5: 'Happy to lend in any industry to small and mid size businesses, nonprofits and to independent contractors and sole proprietors.',
  block6: [
    'Restaurants',
    'Distributors',
    'Catering',
    'Bars',
    'Grocery Stores',
    'Convenience Stores',
    'Food Growers',
    'Construction',
    'Electrical',
    'Electrical',
    'HVAC',
    'Auto Repair',
    'Manufacturing',
    'Medical',
    'Entertainment',
    'Personal Grooming',
    'Hospitality',
    'Child Care',
  ],
  block7: [
    `Payroll Records`,
    `Full year of payroll processor records and / or payroll tax filings (e.g. Forms 940, 941, 944) covering January 2019 through February 2020. Note: Many payroll processors have created custom PPP reports for their customers in their online portals.`,

    `Identifying Business Information`,
    `Information to help identify your business such as a voided check, EIN / TIN information, proof of business (e.g. Articles of Incorporation)`,

    `Individual Applicant Identification`,
    `Identification documents for the individual applying on behalf of the business to validate date of birth and address such as a valid government issued ID and additional proof of address`,

    `Company Ownership Structure Documentation`,
    `Information on the ownership structure of the company (an individual can fill out the application on behalf of multiple owners) along with date of birth and other information on each of the owners with more than 20% stake in the company`,
  ] as [string, string, string, string, string, string, string, string],
};

const IndexPage: React.FunctionComponent<{ props?: typeof DEFAULT_BLOCK_TEXT; }> = ({ props = DEFAULT_BLOCK_TEXT }) => {
  const { dispatch } = useContext(UserContext);
  const [readyClass, setReadyClass] = useState('pagenotready');
  const [successMessage, setSuccessMessage] = useState('');
  const {
    state: {
      isLoggedIn,
    },
  } = useContext(UserContext);
  // There may be a better way to redirect when logged in // TODO
  useEffect(() => {
    if (isLoggedIn) {
      router.replace('/form/instructions');
    }
    if (readyClass === 'pagenotready') {
      setReadyClass('pageready');
    }

    if (readyClass === 'pageready') {
      trackGA_Event('Landing Page', 'load', 'Landing Page');
      trackGA_PageView(window.location.pathname + window.location.search);
    }
  }, [readyClass, isLoggedIn]);

  const handleSubmit = (user, cb) => {
    const paramsValue = generateGuid();
    const userBody = { ...user, ...paramsValue };
    // Create account
    fetchSwal.post('/api/users', userBody).then((data) => {
      if (data.ok !== false) {
        try {
          fbq('track', 'CompleteRegistration', {
            value: 0.00,
            currency: 'USD'
          });
        } catch (e) {
          console.error(e);
        }
        dispatch({ type: 'fetch' });
        trackGA_Event('Landing Page', 'load', 'Signed Up');
        redirectTo('/form/2483?step=0');
      } else if (cb) {
        cb();
      }
    }).catch((_err) => {
      if (cb) cb();
    });
  };

  return (
    <Layout transparent>
      <div className="homepage">
        <div className="hero-img-wrapper">
          <div className="hero-gradient">
            <Container maxWidth="xl" style={{ display: 'flex', padding: '0px' }}>
              <div className={`hero-tag-wrapper ${readyClass}`} style={{ display: 'inline-block' }}>
                <div className="hero-tag">
                  {props.block1}
                </div>
                <div className="hero-subtag">
                  {props.block2}
                </div>
              </div>
              <Hidden smDown>
                <div className="float-right">
                  <h2 style={{ fontWeight: 700 }}>Let’s get started</h2>
                  <div style={{
                    paddingBottom: '16px', color: '#000000', textAlign: 'center', fontSize: '14px',
                  }}
                  >
                    Create your free PPPays account
                  </div>
                  <SignUp
                    tosCompany="PPPays"
                    compact
                    primaryColor="#000000"
                    successLabel={successMessage}
                    onSubmit={handleSubmit}
                  />
                  <div className="center">
                    <Link href="/signin">
                      <a style={{ color: '#000000', fontSize: '14px' }}>Sign in instead</a>
                    </Link>
                  </div>
                </div>
              </Hidden>
            </Container>
          </div>
        </div>
        <Hidden mdUp>
          <div>
            <div
              style={{
                background: 'white',
                borderRadius: '5px',
                padding: '24px',
              }}
            >
              <div className="signupcallout" style={{ fontWeight: 700 }}>Let’s get started</div>
              <div style={{
                paddingBottom: '16px', color: '#000000', textAlign: 'center', fontSize: '14px',
              }}
              >
                Create your free PPPays account
              </div>
              <SignUp
                compact
                successLabel={successMessage}
                onSubmit={handleSubmit}
              />
            </div>
          </div>
        </Hidden>
        <Container maxWidth="xl" style={{ paddingBottom: '60px' }}>
          <div className="section1">
            <div className="callout">
              {props.block3}
            </div>
            <div className="table">
              <Grid
                container
                style={{
                  textAlign: 'center', padding: '0', background: 'white', maxWidth: '1400px',
                }}
              >
                <Grid item xs={12} sm={12} md={3}>
                  <div className="tableitem">
                    <div className="icon">2.5x</div>
                    <div className="title">Monthly Payroll Coverage</div>
                    <div className="tabletext">
                      Get up to 2.5x or 250% your average monthly payroll expenses (or average monthly income for non-employers)
                    </div>
                  </div>
                </Grid>
                <Grid item sm={12} md={3}>
                  <div className="tableitem">
                    <div className="icon">1%</div>
                    <div className="title">Interest</div>
                    <div className="tabletext">
                      1% annual percentage rate with maturity of 5 years and first payment deferred for 6 months. No fees.
                    </div>
                  </div>
                </Grid>
                <Grid item sm={12} md={3}>
                  <div className="tableitem">
                    <div className="icon">0</div>
                    <div className="title">Collateral</div>
                    <div className="tabletext">
                      No collateral or personal guarantees are required. Loan forgiveness if funds are used to retain existing employees.
                    </div>
                  </div>
                </Grid>
                <Grid item sm={12} md={3}>
                  <div className="tableitem">
                    <div className="icon">{props.block4[0]}</div>
                    <div className="title">{props.block4[1]}</div>
                    <div className="tabletext">
                      {props.block4[2]}
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </Container>
        {/* <div className="hero-video">
          <Grid
            style={{ maxWidth: '1400px', margin: '0 auto' }}
            container
            justify="space-between"
            alignItems="center"
          >
            <Grid item xs={12} md={6} style={{ padding: '16px 0' }}>
              <LazyLoad height={300} offset={200} once>
                <div className="video-container">
                  <div className="quotes">{Quotes}</div>
                  <WistiaEmbed
                    playerColor="#000000"
                    hashedId="vyd0j3l8j8"
                  />
                </div>
              </LazyLoad>
            </Grid>
            <Grid container item xs={12} md={5} style={{ padding: '48px' }}>
              <Grid item xs={6} md={6} style={{ display: 'flex', padding: '24px' }}>
                <LazyLoad height={100} offset={100} once>
                  <div style={{ margin: '0 auto' }}>{award1}</div>
                </LazyLoad>
              </Grid>
              <Grid item xs={6} md={6} style={{ display: 'flex', padding: '24px' }}>
                <LazyLoad height={100} duration={100} once>
                  <div style={{ margin: '0 auto' }}>{award2}</div>
                </LazyLoad>
              </Grid>
              <Grid item xs={6} md={6} style={{ display: 'flex', padding: '24px' }}>
                <LazyLoad height={100} offset={100} once>
                  <div style={{ margin: '0 auto' }}>{award3}</div>
                </LazyLoad>
              </Grid>
              <Grid item xs={6} md={6} style={{ display: 'flex', padding: '24px' }}>
                <LazyLoad height={100} offset={100} once>
                  <div style={{ margin: '0 auto' }}>{award4}</div>
                </LazyLoad>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <div className="ourhistory">
          <Hidden smDown>
            <div style={{
              position: 'absolute', right: '36px', top: '0', background: '#000000', borderRadius: '50%', width: '150px', height: '150px',
            }}
            />
          </Hidden>
          <Grid
            style={{ maxWidth: '1400px', margin: '0 auto' }}
            container
            justify="space-between"
            alignItems="center"
          >
            <Grid item xs={12} md={6}>
              <div className="our-history-hero-tag" style={{ color: 'black' }}>
                Our History
              </div>
              <p>
                In 1994, The Loan Source Inc. was approved by the U.S. Small Business Administration (SBA) to acquire one of only fourteen Small Business Lending Company (SBLC) licenses.
              </p>
              <p>
                SBLCs are non-bank lending institutions authorized to make small business Government Guaranteed Loans (SBA loans), nationwide, under the SBA 7(a) program.
              </p>
              <p>
                While many large public companies have owned and operated a SBLC license, all have been transferred, sold, or otherwise acquired since the SBLC license program was originally created in the mid 1970s.
              </p>
              <p>
                The Loan Source has been under the same control and management since its inception, making us the oldest holder of a SBLC license. Today, we continue to proudly help small business with 7(a) loans, PPP loans, and SBA Disaster Loans.
              </p>
            </Grid>
            <Grid item xs={12} md={5}>
              <div className="history-hero-tag">
                The Loan Source is the oldest holder of 1 of 14 SBLC licenses
                <div
                  style={{
                    height: '8px', width: '80px', bottom: '-32px', left: 'calc(50% - 40px)',
                  }}
                  className="underline"
                />
              </div>
            </Grid>
          </Grid>
        </div> */}
        <div style={{backgroundColor: '#F7F8FA'}}>
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{
              padding: '0', maxWidth: '1200px', marginLeft: 'auto', marginRight: 'auto',
            }}
          >
            <Hidden smDown>
              <Grid item xs={12} md={6}>
                <LazyLoad height={500} offset={100} once>
                  <div style={{ position: 'relative', display: 'flex' }}>
                    <img
                      className="testimonyimage"
                      style={{
                        maxWidth: '500px',
                      }}
                      src={require('../public/img/commitment.png')}
                    />
                  </div>
                </LazyLoad>
              </Grid>
            </Hidden>
            <Grid
              item
              xs={12}
              md={6}
              style={{
                display: 'flex', justifyContent: 'center', alignItems: 'center', maxWidth: '500px',
              }}
            >
              <div className="commitment-mobile" style={{ padding: '24px' }}>
                <div style={{ fontSize: '28px', fontWeight: 700, marginBottom: '8px' }}>How PPPays helps:</div>
                <div style={{ fontSize: '14px', margin: '16px 0 24px' }}>Our bank partner is one of the most savvy and efficient lenders in the United States. Our platform empowers you to:</div>
                <div style={{ fontSize: '14px' }}>
                  <div style={{display: 'flex', margin: '16px 0'}}>
                    <img width="auto" height="18px" style={{marginRight: '8px'}} src={require('../public/img/checkmark.png')} />
                    {'Complete your digital application and submit in <30 minutes'}
                  </div>
                  <div style={{display: 'flex', margin: '16px 0'}}>
                  <img width="auto" height="18px" style={{marginRight: '8px'}} src={require('../public/img/checkmark.png')} />
                    Get your loan processed faster when compared to other lenders (days, not weeks)
                  </div>
                  <div style={{display: 'flex', margin: '16px 0'}}>
                  <img width="auto" height="18px" style={{marginRight: '8px'}} src={require('../public/img/checkmark.png')} />
                    Expedited loan submission via our proprietary technology which interfaces directly with the SBA’s online electronic system
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className="industries">
          <Grid container>
            <Grid item xs={12} md={6}>
              <div className="industry-hero-tag">
                {props.block5}
                <div
                  style={{
                    height: '8px', width: '80px', bottom: '-32px', left: '0',
                  }}
                  className="underline"
                />
              </div>
            </Grid>
            <Grid item xs={12} md={3}>
              <ul style={{ listStyleType: 'none' }}>
                {props.block6.slice(0, Math.ceil(props.block6.length / 2)).map(item => <li key={item}>{item}</li>)}
              </ul>
            </Grid>
            <Grid item xs={12} md={3}>
              <ul style={{ listStyleType: 'none' }}>
                {props.block6.slice(Math.ceil(props.block6.length / 2)).map(item => <li key={item}>{item}</li>)}
              </ul>
            </Grid>
          </Grid>
        </div>
        <div className="commitment flip">
          <div style={{ transform: 'matrix(-1, 0, 0, 1, 0, 0)' }}>
            <Grid
              container
              justify="space-between"
              alignItems="center"
              style={{
                padding: '0', maxWidth: '1200px', marginLeft: 'auto', marginRight: 'auto',
              }}
            >
              <Hidden smDown>
                <Grid item xs={12} md={6}>
                  <LazyLoad height={500} offset={100} once>
                    <div style={{ position: 'relative' }}>
                      <img
                        className="testimonyimage"
                        style={{
                          maxWidth: '500px', background: 'lightgray', borderRadius: '50%', margin: 'auto',
                        }}
                        src={require('../public/img/highfive.png')}
                      />
                      <Hidden smDown>
                        <div style={{
                          position: 'absolute', bottom: '-50px', left: '-50px', background: 'rgba(220, 225, 239, 0.8)', borderRadius: '50%', width: '175px', height: '175px', zIndex: -1,
                        }}
                        />
                      </Hidden>
                    </div>
                  </LazyLoad>
                </Grid>
              </Hidden>
              <Grid
                item
                xs={12}
                md={6}
                style={{
                  display: 'flex', justifyContent: 'center', alignItems: 'center', maxWidth: '500px',
                }}
              >
                <div className="commitment-mobile" style={{ padding: '24px' }}>
                  <div style={{ fontSize: '36px', fontWeight: 700, marginBottom: '8px' }}>What do I need to start?</div>
                  <div style={{ fontSize: '20px', marginBottom: '8px' }}>Use this checklist to prepare the information and documentation you’ll need to apply:</div>
                  <div style={{ padding: '24px 0 8px' }}>
                    <div style={{ display: 'inline-block', marginRight: '8px' }}>
                      <span style={{ fontWeight: 700, fontSize: '16px' }}>{props.block7[0]}</span>
                    </div>
                  </div>
                  <div style={{ display: 'inline-block', fontSize: '14px', fontWeight: 300 }}>
                    {props.block7[1]}
                  </div>
                  <div style={{ padding: '24px 0 8px' }}>
                    <div style={{ display: 'inline-block', marginRight: '8px' }}>
                      <span style={{ fontWeight: 700, fontSize: '16px' }}>{props.block7[2]}</span>
                    </div>
                  </div>
                  <div style={{ display: 'inline-block', fontSize: '14px', fontWeight: 300 }}>
                    {props.block7[3]}
                  </div>
                  <div style={{ padding: '24px 0 8px' }}>
                    <div style={{ display: 'inline-block', marginRight: '8px' }}>
                      <span style={{ fontWeight: 700, fontSize: '16px' }}>{props.block7[4]}</span>
                    </div>
                  </div>
                  <div style={{ display: 'inline-block', fontSize: '14px', fontWeight: 300 }}>
                    {props.block7[5]}
                  </div>
                  <div style={{ padding: '24px 0 8px' }}>
                    <div style={{ display: 'inline-block', marginRight: '8px' }}>
                      <span style={{ fontWeight: 700, fontSize: '16px' }}>{props.block7[6]}</span>
                    </div>
                  </div>
                  <div style={{ display: 'inline-block', fontSize: '14px', fontWeight: 300 }}>
                    {props.block7[7]}
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
        <div style={{ backgroundColor: '#F3F5F6', height: '144px', display: 'flex' }}>
          <QueryPreservingLink href="/faq" as="/faq">
            <a style={{
              fontWeight: 700, fontSize: '36px', margin: 'auto', color: '#000000',
            }}
            >
              Read our FAQ here
            </a>
          </QueryPreservingLink>
        </div>
        <Footer />
        <style jsx>
          {`
          .pageready {
            opacity: 1;
          }
          .pagenotready {
            opacity: 0;
          }
          .ourhistory {
            position: relative;
            background: #F7F8FA;
            padding: 120px 24px;
          }
          @media only screen and (min-width: 960px) {
            .quotes {
              position: absolute;
              top -80px;
            }
            .video-container {
              position: relative;
            }
            li {
              font-size: 24px !important;
            }
            .ourhistory {
              border-radius: 0 250px 0 0
            }
            :global(#__next) {
              border-radius: 0 0 0 250px;
            }
            .commitment {
              border-radius: 0 250px 0 0;
            }
            .hero-img-wrapper {
              border-radius: 0 0 0 250px;

              min-height: calc(750px - 76px);
              max-height: calc(1000px - 76px);
              background: linear-gradient(180deg, rgba(255,255,255,0) 13.19%, rgba(255, 255, 255, 0) 27.15%), linear-gradient(360deg, rgba(31, 36, 51, 0.5) 51.29%, rgba(31, 36, 51, 0) 58.7%);
            }
            :global(#__next) {
              min-height: 750px;
              max-height: 1000px;
            }
            .hero-video {
              padding: 16px 24px;
            }
          }
          .section1 .callout {
            max-width: 1400px;
            margin: auto;
            font-size: 36px;
          }
          :global(html) {
            background: white;
            overflow-x: hidden;
          }
          :global(nav) {
            background: unset !important;
          }
          :global(#__next) {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 80%;
            background: url('${require('../public/img/bg3.jpg')}');
            background-size: cover;
          }
          :global(main) {
          }
          ul {
            list-style-type: none;
          }
          li {
            color: #4B6075;
            font-size: 14px;
            font-weight: 300;
          }
          .industries {
            padding: 48px 24px;
            margin: 48px auto;
            max-width: 1400px;
          }
          .industry-hero-tag, .history-hero-tag {
            position: relative;
            color: #000000;
            font-size: 48px;
            font-family: 'Merriweather', serif;
            font-weight: 700;
          }
        .center {
          margin-top: 10px;
          text-align: center;
          font-size: 20px;
        }
        .commitment {
          padding: 32px 0;
          background: linear-gradient(75.06deg, #18307F 53.2%, #DF1E2E 128.22%);
          color: white;
        }
        .flip {
          transform: matrix(-1, 0, 0, 1, 0, 0);
        }
        @media only screen and (max-width: 960px) {
          .quotes {
            display: none;
          }
          .section3 {
            padding: 40px 0 !important;
          }
          .section3 .pricing-large {
            margin: 10px 0 !important;
          }
          .section3 .price {
            font-size: 42px !important;
          }
          .section3 .strike {
            font-size: 24px !important;
            padding-left: 8px !important;
          }
          .section3 .unit {
            font-size: 18px !important;
            padding-left: 8px !important;
          }
          .section3 .callout {
            font-size: 20px !important;
            padding: 0 40px 20px !important;
          }
          .callout {
            font-size: 24px !important;
          }
          .tabletext {
            text-align: center;
            margin-left: auto !important;
            margin-right: auto !important;
          }
          .section3 .disclaimer {
            font-size: 16px !important;
            margin-bottom: 25px !important;
          }
          .section3 .pricing-small {
            font-size: 18px !important;
          }
          .section1 {
            padding-bottom: 0 !important;
            margin-top: 20px !important;
            padding-top: 60px !important;
            border-top: 1px solid #DCDDDE !important;
          }
          .commitment-mobile {
            margin-bottom: 40px !important;
            margin-top: 40px !important;
          }
          .section2 {
            margin-top: 0 !important;
            margin-bottom: 0 !important;
          }
          .section2 .quoteText {
            font-size: 20px !important;
          }
          .hero-gradient {

          }
          section1 .callout {
            padding: 20px !important;
            font-size: 24px !important;
          }
          .hero-tag-wrapper {
            margin: auto !important;
          }
          .hero-tag {
            font-size: 32px !important;
            font-weight: 700 !important;
            line-height: 115%;
          }
          .our-history-hero-tag {
            font-size: 32px !important;
            font-weight: 700 !important;
            line-height: 115%;
          }
          .hero-subtag {
            font-size: 14px !important;
            font-weight: 300 !important;
          }
        }
        .float-right {
          background-color: white;
          box-sizing: border-box;
          border: 1px solid #4B6075;
          border-radius: 5px;
          box-sizing: border-box;
          padding: 40px;
          float: right;
          margin: auto auto 36px auto;
          max-width: 450px;
          min-width: 450px;
        }
        .float-right h2 {
          text-align: center;
          font-size: 34px;
          font-weight: normal;
          margin-bottom: 0;
          margin-top: 0;
        }
        .float-right h3 {
          text-align: center;
          font-size: 16px;
          font-weight: normal;
          margin-top: 5px;
          margin-bottom: 10px;
          text-align: center;
          color: #4B6075;
        }
        .homepage :global(a.button),
        .homepage :global(a.button:visited),
        .homepage :global(a.button:active) {
          display: inline-block;
          color: white;
          background: #000000;
          border-radius: 5px;
          padding: 8px 16px;
          font-size: 20px;
        }
        .signupcallout {
          font-size: 24px;
          text-align: center;
          padding: 0 0 8px;
        }
        .signupsubcallout {
          font-size: 16px;
          text-align: center;
          color: #4B6075;
          padding: 0 0 16px;
        }
        .callout {
          font-color: color: #1F2933;
          font-size: 36px;
          font-weight: bold;
          line-height: 125%;
          text-align: center;
        }
        .hero-tag-wrapper {
          margin: auto auto 64px;
          max-width: 550px;
        }
        .hero-tag {
          color: white;
          font-size: 40px;
          padding: 32px 0;
          margin-left: 36px;
          width: auto;
          font-weight: 700;
        }
        .our-history-hero-tag {
          font-size: 48px;
          padding: 4px 0;
          width: auto;
          font-weight: 700;
        }
        p {
          color: #1F2433;
        }
        .hero-subtag {
          padding: 4px 0;
          margin-left: 36px;
          color: white;
          font-size: 20px;
          font-weight: 300;
        }
        .hero-img-wrapper {
          position: relative;
          overflow: hidden;
          width: 100%;
          height: calc(80vh - 76px);
        }
        .hero-gradient {
          display: flex;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
        }
        .hero-img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: top;
        }
        .section1 {
          background: white;
          padding: 60px 0px 0px 0px;
        }
        .section1 .table {
          margin-top: 32px;
          display: flex;
          flex-direction: row;
          justify-content: space-evenly;
        }
        .section1 .table > div {
          text-align: center;
          width: 300px;
          font-size: 20px;
        }
        .tableitem {
          padding: 24px 24px;
          display: flex;
          flex-direction: column;
        }
        .tabletext {
          max-width: 500px;
          font-size: 14px;
        }
        .section1 .table .title {
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 8px;
        }
        .section1 .table .icon {
          display: inline-block;
          padding-bottom: 16px;
          color: #000000;
          font-weight: 700;
          font-family: 'Merriweather', serif;
          font-size: 48px;
        }
        .section2 {
          // background: white;
          display: flex;
          flex-direction: row;
          margin-top: 140px;
          margin: 80px auto 80px auto;
        }
        .section2 .testimonyimage {
          width: 100%;
          height: 100%;
          object-fit: cover;
          max-height: 500px;
        }
        .section2 .left {
          width: 544px;
          font-size: 24px;
          padding: 80px 40px;
        }
        .section2 .quote {
          margin-bottom: 40px;
        }
        .homepage :global(a.button),
        .homepage :global(a.button:visited),
        .homepage :global(a.button:active) {
          display: inline-block;
          color: white;
          background: #000000;
          border-radius: 5px;
          padding: 8px 16px;
          font-size: 20px;
        }
        .section2 .quoteText {
          margin-bottom: 50px;
          font-size: 24px;
        }
        .section3 {
          text-align: center;
          padding-bottom: 140px;
        }
        .section3 .pricing-small {
          color: #2CA01C;
          font-size: 34px;
          margin-top: 20px;
        }
        .section3 .pricing-large {
          display: flex;
          margin-top: 20px;
          align-items: center;
          justify-content: center;
        }
        .section3 .price {
          color: #2CA01C;
          font-size: 120px;
          font-weight: bold;
        }
        .section3 .strike {
          font-size: 96px;
          text-decoration: line-through;
          padding-left: 60px;
        }
        .section3 .unit {
          font-size: 48px;
          padding-left: 18px;
        }
        .section3 .disclaimer {
          font-size: 24px;
          margin-bottom: 25px;
        }
        .underline {
          position: absolute;
          background: linear-gradient(30.96deg, #18307F 53.2%, #DF1E2E 128.22%);
          border-radius: 20px;
          transform: matrix(1, 0, 0, -1, 0, 0);
        }
        @media only screen and (max-width: 960px) {
          .hero-img-wrapper {
            max-height: 500px;
          }
          :global(#__next) {
            max-height: 500px;
            background-position-x: -200px;
          }
          .ourhistory {
            padding: 60px 24px;
          }
          .industry-hero-tag, .history-hero-tag {
            font-size: 24px !important;
          }
          .underline {
            display: none;
          }
        }
      `}
        </style>
      </div>
    </Layout>
  );
};
export default IndexPage;
