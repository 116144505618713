import { NextPage } from 'next';
import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { User } from '../lib/user';


type Props = {
  compact?: boolean,
  successLabel?: string;
  tosCompany?: string;
  primaryColor?: string;
  onSubmit: (user: Pick<User, 'email' | 'firstname' | 'lastname'>, callback?: Function) => void
}

const SignupSchema = Yup.object().shape({
  email: Yup.string().email("Email is invalid").required("Email is required"),
  password: Yup.string().min(9, "Password must be at least ${min} characters").required("Password is required"),
  firstname: Yup.string().required("First name is required"),
  lastname: Yup.string().required("Last name is required"),
});

const SignUp: NextPage<Props> = ({ compact = false, onSubmit, successLabel, tosCompany = 'PPPay', primaryColor = '#1382E9'  }) => {
  return (
    <div>
      <Formik initialValues={{
          email: '',
          password: '',
          firstname: '',
          lastname: '',
        }}
        validationSchema={SignupSchema}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          onSubmit(values, () => {
            setSubmitting(false);
          });
        }}
      >
        {({ errors, touched, isSubmitting }) => (
        <Form>
          <label htmlFor="email">
            <div>Email address*</div>
          </label>
          <div className="form-control-wrapper">
          < Field disabled={isSubmitting} name="email" autoComplete="email" />
          </div>
          <div className="validate">
            {errors.email && touched.email && errors.email}&nbsp;
          </div>
          <label htmlFor="password">
            <div>Password*</div>
          </label>
          <div className="form-control-wrapper">
            <Field disabled={isSubmitting} name="password" type="password" autoComplete="current-password" />
          </div>
          <div className="validate">
            {errors.password && touched.password && errors.password}&nbsp;
          </div>
          <div className={`names ${compact ? "compact" : ''}`}>
            <div>
              <label htmlFor="firstname">
                <div>First Name*</div>
              </label>
              <div className="form-control-wrapper">
                <Field disabled={isSubmitting} name="firstname" autoComplete="first-name"/>
              </div>
              <div className="validate">
                {errors.firstname && touched.firstname && errors.firstname}&nbsp;
              </div>
            </div>
            <div>
              <label htmlFor="lastname">
                <div>Last Name*</div>
              </label>
              <div className="form-control-wrapper">
                <Field disabled={isSubmitting} name="lastname" autoComplete="last-name" />
              </div>
              <div className="validate">
                {errors.lastname && touched.lastname && errors.lastname}&nbsp;
              </div>
            </div>
            </div>
          <div className="disclaimer">{`By clicking Submit, you agree to the ${tosCompany} Terms of Service, Terms of Use, and have read and acknowledge our US Privacy Statement`}</div>
        {successLabel && <div style={{ textAlign: 'center', padding: '28px 0 8px' }}>{successLabel}</div>}
          <div className="text-center center mybutton">
                <button disabled={isSubmitting} type="submit" >{isSubmitting ? "Submitting..." : "Apply Now"}</button>
          </div>
        </Form>
      )}
      </Formik>
      <style jsx>{`
        label > div {
          font-size: 14px;
        }
        .names.compact {
          display: flex;
          justify-content: space-between;
          width: 100%;
        }
        .names.compact > div {
          width: 48%;
        }
        .validate {
          line-height: 16px;
          color: #D92C23;
          font-size: 12px;
        }
        .disclaimer {
          box-sizing: border-box;
          padding: 0 24px;
          margin-top: 8px;
          font-style: oblique;
          text-align: center;
          font-size: 12px;
          color: #4B6075;
        }
        .form-control-wrapper {
          box-sizing: border-box;
          position: relative;
          width: 100%;
        }
        .form-control-wrapper :global(input) {
          color: #4B6075;
          color: black;
          font-size: 14px;
          font-family: inherit;
          box-sizing: border-box;
          position: relative;
          width: 100%;
          margin: 0;
          border-color: black;
          padding: 5px 10px;
        }
        button {
          margin: 20px auto 0 auto;
          padding: 0;
          background: ${primaryColor};
          width: 165px;
          height: 48px;
          font-size: 16px;
          color: white;
          border-radius: 30px;
          border: none;
          cursor: pointer;
        }
        button:disabled {
          color: gray;
          cursor: no-drop;
        }
        .center {
                  margin-top: 10px;
                  text-align: center;
                  font-size: 20px;
                }
      `}</style>
    </div>
  );
};

export { SignUp };
